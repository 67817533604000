document.addEventListener('turbolinks:load', () => {
  setupFacetFeedbackActions();
});

const setupFacetFeedbackActions = () => {
  const sliders = document.querySelectorAll('.wcs-range-slider');

  if (!sliders) return;

  sliders.forEach((slider) => {
    slider.addEventListener('input', (event) => {
      const sliderValue = event.target.value;
      const textField = document.getElementById(`text-field-container-${slider.id}`);
      const facetDetails = document.getElementById(`facet-details-${slider.id}`);

      if (sliderValue < 0) {
        if (!textField.classList.contains('hidden')) return;

        textField.classList.remove('fade-out', 'slide-down', 'hidden');
        textField.classList.add('fade-in', 'slide-up');
        facetDetails.classList.remove('fade-in');
        facetDetails.classList.add('fade-out');

        setTimeout(() => {
          facetDetails.classList.add('hidden');
        }, 500); // Match the duration of the fade-out transition
      } else {
        if (!facetDetails.classList.contains('hidden')) return;

        textField.classList.remove('fade-in', 'slide-up');
        textField.classList.add('fade-out', 'slide-down');

        setTimeout(() => {
          textField.classList.add('hidden');
        }, 500); // Match the duration of the fade-out transition

        facetDetails.classList.remove('fade-out', 'hidden');
        facetDetails.classList.add('fade-in');
      }
    });
  });
}
